import React, { ComponentType } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

import { Routes } from "../routes";

interface BottomBarButtonProps {
  enable: boolean;
  icon: ComponentType;
  path: Routes;
  title: string;
}

export const BottomBarButton: React.FC<BottomBarButtonProps> = ({
  enable = false,
  icon: Icon,
  path,
  title,
}) => {
  return (
    <Link to={path} style={{ textDecorationLine: "none" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: enable ? "primary.main" : "grey",
        }}
      >
        <Icon />
        <Typography variant="body2" fontWeight="bold">
          {title}
        </Typography>
      </Box>
    </Link>
  );
};
