import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { initializeApp as initializeFirebase } from "firebase/app";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/system";
import { blue } from "@mui/material/colors";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./app.css";
import { env, validateEnvVars } from "./shared/config/envVars";
import { router } from "./components/navigation/router";

validateEnvVars();

initializeFirebase({
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
});

const client = new ApolloClient({
  uri: env.API_URL,
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    primary: {
      main: blue[300],
      contrastText: "white",
    },
  },
  typography: {
    fontSize: 12,
  },
});

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              minHeight: "100vh",
              background:
                "linear-gradient(180deg, rgba(53,191,242,1) 0%, rgba(255,255,255,1) 69%)",
            }}
          >
            <RouterProvider router={router} />
          </Box>
        </ThemeProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
