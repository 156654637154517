import { Box } from "@mui/system";
import React from "react";

import findRideIllustration from "../../assets/find-ride-illustration.svg";
import kinigoLogo from "../../assets/kinigo-logo.svg";
import { SearchCard } from "../../components/searchCard/SearchCard";

const styles = {
  container: {
    position: "relative",
    zIndex: 1,
  },
  illustrationContainer: {
    textAlign: "center",
  },
  illustration: {
    width: "310px",
    maxWidth: "100%",
  },
  logo: {
    width: "140px",
  },
} as const;

interface SearchPageProps {}

export const SearchPage: React.FC<SearchPageProps> = () => {
  return (
    <>
      <Box style={styles.container}>
        <Box sx={styles.illustrationContainer}>
          <img src={kinigoLogo} style={styles.logo} alt="illustration" /> <br />
          <Box height={12} />
          <img
            src={findRideIllustration}
            style={styles.illustration}
            alt="illustration"
          />
        </Box>
        <Box height={18} />
        <Box>
          <SearchCard />
        </Box>
      </Box>
    </>
  );
};
