import React from "react";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { TimelineContent } from "@mui/lab";

interface Address {
  number?: string | null;
  street: string;
  city: string;
  country?: string;
}

interface RideStepsProps {
  endAddress: Address;
  endDate: Date;
  startAddress: Address;
  startDate: Date;
}

export const RideSteps: React.FC<RideStepsProps> = ({
  endAddress,
  endDate,
  startAddress,
  startDate,
}) => {
  return (
    <Timeline
      style={{ padding: 0, margin: 0 }}
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          <Typography style={{ whiteSpace: "nowrap" }} variant="subtitle2">
            {format(new Date(startDate), "hh:mmaaaaa'm'")}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ width: "200px" }}>
          <Typography
            variant="subtitle2"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {startAddress.number} {startAddress.street}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {startAddress.city} {startAddress.country}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
          <Typography style={{ whiteSpace: "nowrap" }} variant="subtitle2">
            {format(new Date(endDate), "hh:mmaaaaa'm'")}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary" />
        </TimelineSeparator>
        <TimelineContent>
          <Typography
            variant="subtitle2"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {endAddress.number} {endAddress.street}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {endAddress.city} {endAddress.country}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};
