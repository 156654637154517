import React from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ShareIcon from "@mui/icons-material/StarRateRounded";
import { blue } from "@mui/material/colors";
import { FindRidesQuery } from "../../libs/apollo/introspection";
import { RideSteps } from "./RideSteps";

interface ResultCardProps {
  ride: FindRidesQuery["findRides"][number];
}

export const ResultCard: React.FC<ResultCardProps> = ({ ride }) => {
  return (
    <>
      <Card sx={{ maxWidth: 345, borderRadius: 3 }}>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: blue[300] }}
              aria-label="recipe"
              src={ride.driver.profilePicture || ""}
            >
              {ride.driver.username[0]}
            </Avatar>
          }
          title={`${ride.driver.username}`}
          titleTypographyProps={{ variant: "subtitle2" }}
          subheader={
            <div style={{ display: "flex", alignItems: "center" }}>
              <ShareIcon fontSize="small" color="warning" />
              <Typography variant="body2" color="text.secondary">
                {ride.driver.averageRate}&nbsp;
              </Typography>
              <Typography variant="subtitle2">
                ({ride.driver.reviewsCount})
              </Typography>
            </div>
          }
        />
        <CardContent style={{ padding: 0 }}>
          <RideSteps
            endAddress={ride.endAddress}
            endDate={ride.endDate}
            startAddress={ride.startAddress}
            startDate={ride.endDate}
          />
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disableElevation
            endIcon={<ArrowForwardIosIcon />}
            sx={{ marginLeft: "auto", marginRight: 1, marginBottom: 1 }}
            variant="contained"
          >
            See more
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
