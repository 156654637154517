import React from "react";

import { ResultCard } from "../../components/resultCard/ResultCard";
import { useFindRidesQuery } from "../../libs/apollo/introspection";

const fixDate = new Date("2023").toISOString(); // TODO - to remove later

export const ResultsPage: React.FC = () => {
  const { data } = useFindRidesQuery({
    variables: {
      endCity: "Marathonas",
      endDate: fixDate,
      startCity: "Athina",
      startDate: fixDate,
    },
  });

  return (
    <>
      {data?.findRides.map((ride) => (
        <div key={ride.id}>
          <ResultCard ride={ride} />
          <div style={{ height: 24 }} />
        </div>
      ))}
    </>
  );
};
