import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { BottomBar } from "../../components/navigation/bottomBar/BottomBar";

const BOTTOM_BAR_HEIGHT = 60;
const styles = {
  container: {
    margin: "auto",
    maxWidth: 780,
    padding: "16px",
    paddingBottom: `${BOTTOM_BAR_HEIGHT + 40}px`,
  },
};

interface RootProps {}

export const Root: React.FC<RootProps> = () => {
  return (
    <Box sx={styles.container}>
      <Outlet />
      <BottomBar height={BOTTOM_BAR_HEIGHT} />
    </Box>
  );
};
