import { createBrowserRouter } from "react-router-dom";

import { ResultsPage } from "../../pages/resultPage/ResultsPage";
import { Root } from "../../pages/root/Root";
import { SearchPage } from "../../pages/search/Search.page";
import { Routes } from "./routes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: Routes.Search,
        element: <SearchPage />,
        children: [
          {
            path: Routes.Results,
            element: <ResultsPage />,
          },
        ],
      },
      {
        path: Routes.Publish,
        element: <h1>Publish page in progress</h1>,
      },
      {
        path: Routes.Messages,
        element: <h1>Messages page in progress</h1>,
      },
      {
        path: Routes.MyRides,
        element: <h1>My rides page in progress</h1>,
      },
      {
        path: Routes.Profile,
        element: <h1>Profile page in progress</h1>,
      },
    ],
  },
]);
