import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RoomIcon from "@mui/icons-material/Room";

const styles = {
  container: {
    margin: "0 auto",
    maxWidth: "410px",
  },
  formContainer: {
    margin: "24px 0",
  },
  tabButton: {
    display: "flex",
    flex: 1,
  },
  tabButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchButton: {
    width: "100%",
  },
};

interface SearchCardProps {}

export const SearchCard: React.FC<SearchCardProps> = () => {
  return (
    <Card sx={styles.container}>
      <CardContent>
        <Box sx={styles.tabButtonsContainer}>
          <Button
            disableElevation
            style={styles.tabButton}
            variant="contained"
            color="primary"
          >
            Find Ride
          </Button>
          <Box width={12} />
          <Button disableElevation style={styles.tabButton} variant="outlined">
            Publish Ride
          </Button>
        </Box>
        <Box sx={styles.formContainer}>
          <Box display="flex" alignItems="center">
            <FiberManualRecordIcon
              color="primary"
              fontSize="inherit"
              sx={{
                fontSize: "12px",
                mr: 1.5,
                ml: 0.5,
              }}
            />
            <TextField
              fullWidth
              placeholder="Choose your starting point"
              variant="standard"
            />
          </Box>
          <Box height={22} />
          <Box display="flex" alignItems="center">
            <RoomIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
            <TextField
              fullWidth
              placeholder="Choose destination"
              variant="standard"
            />
          </Box>
          <Box height={22} />
          <Box display="flex">
            <AccessTimeIcon
              fontSize="small"
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
            <TextField placeholder="Pick a time" variant="standard" />
            <Box width={22} />
            <PeopleOutlineIcon
              fontSize="small"
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
            <TextField placeholder="Number of people" variant="standard" />
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          disableElevation
          variant="contained"
          style={styles.searchButton}
        >
          Find
        </Button>
      </CardActions>
    </Card>
  );
};
