import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddRider = {
  rideId: Scalars['Int'];
  riderId: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  number?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  street: Scalars['String'];
};

export type CreateMessageInput = {
  message: Scalars['String'];
  receiverId: Scalars['Int'];
  senderId: Scalars['Int'];
};

export type CreateRide = {
  driverId: Scalars['Int'];
  endAddress: InputCreateAddress;
  endDate: Scalars['DateTime'];
  placesTotalCount: Scalars['Int'];
  price: Scalars['Float'];
  startAddress: InputCreateAddress;
  startDate: Scalars['DateTime'];
};

export type CreateRideResult = {
  __typename?: 'CreateRideResult';
  endAddress: Address;
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  placesTotalCount: Scalars['Int'];
  price: Scalars['Float'];
  startAddress: Address;
  startDate: Scalars['DateTime'];
};

export type CreateUserResult = {
  __typename?: 'CreateUserResult';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type InputCreateAddress = {
  city: Scalars['String'];
  district?: InputMaybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  number?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
};

export type InputCreateReview = {
  comment?: InputMaybe<Scalars['String']>;
  rate: Scalars['Int'];
  reviewedId: Scalars['Int'];
  reviewerId: Scalars['Int'];
  rideId: Scalars['Int'];
};

export type InputCreateUser = {
  email: Scalars['String'];
  firebaseId: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  message: Scalars['String'];
  receiverId: Scalars['Int'];
  senderId: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addRider: Ride;
  createAddress: Address;
  createMessage: Message;
  createReview: Review;
  createRide: CreateRideResult;
  createUser: CreateUserResult;
  removeMessage: Message;
};


export type MutationAddRiderArgs = {
  rider: AddRider;
};


export type MutationCreateAddressArgs = {
  address: InputCreateAddress;
};


export type MutationCreateMessageArgs = {
  createMessageInput: CreateMessageInput;
};


export type MutationCreateReviewArgs = {
  ride: InputCreateReview;
};


export type MutationCreateRideArgs = {
  ride: CreateRide;
};


export type MutationCreateUserArgs = {
  user: InputCreateUser;
};


export type MutationRemoveMessageArgs = {
  id: Scalars['Int'];
};

export type PublicUser = {
  __typename?: 'PublicUser';
  averageRate: Scalars['Float'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  reviewsCount: Scalars['Float'];
  username: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  findRides: Array<Ride>;
  getAddress: Address;
  getMessages: Array<Message>;
  getPublicUser: PublicUser;
  getRide: Ride;
  getUser: User;
  login: User;
  message: Message;
};


export type QueryFindRidesArgs = {
  endCity: Scalars['String'];
  endDate: Scalars['DateTime'];
  startCity: Scalars['String'];
  startDate: Scalars['DateTime'];
};


export type QueryGetAddressArgs = {
  id: Scalars['Int'];
};


export type QueryGetMessagesArgs = {
  receiverId: Scalars['Float'];
  senderId: Scalars['Float'];
};


export type QueryGetPublicUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetRideArgs = {
  id: Scalars['Int'];
};


export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


export type QueryLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type QueryMessageArgs = {
  id: Scalars['Int'];
};

export type Review = {
  __typename?: 'Review';
  comment?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  rate: Scalars['Int'];
  reviewedId: Scalars['Int'];
  reviewerId: Scalars['Int'];
  rideId: Scalars['Int'];
};

export type Ride = {
  __typename?: 'Ride';
  availablePlaces: Scalars['Int'];
  driver: User;
  endAddress: Address;
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  placesTotalCount: Scalars['Int'];
  price: Scalars['Float'];
  riders: Array<User>;
  startAddress: Address;
  startDate: Scalars['DateTime'];
};

export type User = {
  __typename?: 'User';
  averageRate: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  reviews: Array<Review>;
  reviewsCount: Scalars['Float'];
  username: Scalars['String'];
};

export type FindRidesQueryVariables = Exact<{
  endCity: Scalars['String'];
  endDate: Scalars['DateTime'];
  startCity: Scalars['String'];
  startDate: Scalars['DateTime'];
}>;


export type FindRidesQuery = { __typename?: 'Query', findRides: Array<{ __typename?: 'Ride', id: number, availablePlaces: number, placesTotalCount: number, price: number, endDate: any, startAddress: { __typename?: 'Address', number?: string | null, street: string, city: string }, endAddress: { __typename?: 'Address', number?: string | null, city: string, street: string }, driver: { __typename?: 'User', profilePicture?: string | null, username: string, reviewsCount: number, averageRate: string } }> };


export const FindRidesDocument = gql`
    query FindRides($endCity: String!, $endDate: DateTime!, $startCity: String!, $startDate: DateTime!) {
  findRides(
    endCity: $endCity
    endDate: $endDate
    startCity: $startCity
    startDate: $startDate
  ) {
    id
    startAddress {
      number
      street
      city
    }
    endAddress {
      number
      city
      street
    }
    availablePlaces
    driver {
      profilePicture
      username
      reviewsCount
      averageRate
    }
    placesTotalCount
    price
    endDate
  }
}
    `;

/**
 * __useFindRidesQuery__
 *
 * To run a query within a React component, call `useFindRidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRidesQuery({
 *   variables: {
 *      endCity: // value for 'endCity'
 *      endDate: // value for 'endDate'
 *      startCity: // value for 'startCity'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useFindRidesQuery(baseOptions: Apollo.QueryHookOptions<FindRidesQuery, FindRidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRidesQuery, FindRidesQueryVariables>(FindRidesDocument, options);
      }
export function useFindRidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRidesQuery, FindRidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRidesQuery, FindRidesQueryVariables>(FindRidesDocument, options);
        }
export type FindRidesQueryHookResult = ReturnType<typeof useFindRidesQuery>;
export type FindRidesLazyQueryHookResult = ReturnType<typeof useFindRidesLazyQuery>;
export type FindRidesQueryResult = Apollo.QueryResult<FindRidesQuery, FindRidesQueryVariables>;