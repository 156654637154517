import React from "react";
import Search from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Box } from "@mui/system";
import { matchPath, useLocation } from "react-router-dom";

import { BottomBarButton } from "./BottomBarButton";
import { Routes } from "../routes";

const buttons = [
  {
    icon: Search,
    title: "Search",
    path: Routes.Search,
  },
  {
    icon: AddCircleOutlineIcon,
    title: "Publish",
    path: Routes.Publish,
  },
  {
    icon: ChatBubbleOutlineIcon,
    title: "Messages",
    path: Routes.Messages,
  },
  {
    icon: DirectionsCarIcon,
    title: "My Rides",
    path: Routes.MyRides,
  },
  {
    icon: PermIdentityIcon,
    title: "Profile",
    path: Routes.Profile,
  },
];

const styles = {
  container: {
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    position: "fixed",
    right: 0,
    zIndex: 50,
  },
  buttonsContainer: {
    alignItems: "center",
    boxShadow: "0px -14px 10px -8px rgba(0, 0, 0, 0.053)",
    display: "flex",
    justifyContent: "space-around",
    margin: "0 auto",
    maxWidth: "750px",
    padding: "8px",
  },
};

interface BottomBarProps {
  height: number;
}

export const BottomBar: React.FC<BottomBarProps> = ({ height }) => {
  const location = useLocation();

  return (
    <Box sx={[styles.container, { height: `${height}px` }]}>
      <Box sx={styles.buttonsContainer}>
        {buttons.map((button) => (
          <BottomBarButton
            enable={!!matchPath(location.pathname, button.path)}
            icon={button.icon}
            key={button.title}
            path={button.path}
            title={button.title}
          />
        ))}
      </Box>
    </Box>
  );
};
