export const env = {
  API_URL: process.env.REACT_APP_API_URL,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
} as const;

type keysOfEnv = keyof typeof env;

export const validateEnvVars = () => {
  let missingEnvVars: string[] = [];

  Object.keys(env).forEach((key) => {
    if (!env[key as keysOfEnv]) {
      missingEnvVars.push(key);
    }
  });

  if (missingEnvVars.length > 0) {
    throw new Error(`It missing the env vars: ${missingEnvVars.join(", ")}`);
  }
};
